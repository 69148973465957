import HostButton from '../hostButton/host-button'
import * as style from '../infoScreen2/style'
const title = "It’s better than forcing people to watch a 15-second AD"
const titleText = "Advertise your brand through a fun experience to"
const titleText2 = "stand out from your competitors"
export default function InfoScreen3() {

    return (
        <style.Container>
            
            <style.ContentContainer style={{justifyContent: 'center'}}>
                <style.Title>{title}</style.Title>
                <div style={{height:'24px'}}/>
                <style.TitleText>{titleText}</style.TitleText>
                <style.TitleText>{titleText2}</style.TitleText>
                <div style={{height:'34px'}}/>
                <HostButton size='large'/>
            </style.ContentContainer>
            
        </style.Container>
    )
}