import { steps } from './steps'
import * as style from './style'
import one from '../../assets/laptop.svg'
import one2 from '../../assets/laptop-full.svg'
import two from '../../assets/calendar.svg'
import three from '../../assets/partner-img.svg'
import four from '../../assets/phone.svg'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";

const title = "Drive more engagement"
const title2 = " to your brand"
// const headerText = "Host an experience on a digital gameshow instead." 
// const headerText2 = "Here’s how it works..."

const imageStyle = {
    'max-width': '100%',
    'max-height': '100%'
}

export default function StepsView() {

    const info1 = steps[0]
    const info2 = steps[1]
    const info3 = steps[2]
    const info4 = steps[3]
    const info5 = steps[4]

    return (
        <style.StepsViewContainer>
            <style.Headercontainer>
                <style.Title>{title}</style.Title>
                <style.Title>{title2}</style.Title>
                {/* <style.title2>The quick brown fox jumps over</style.title2> */}
                {/* <span style={{height: '20px'}}/>
                <style.Text>{headerText}</style.Text>
                <style.Text>{headerText2}</style.Text> */}
            </style.Headercontainer>


            <style.StepContainer>
                <style.Step1Container>
                    
                        <style.imageContainer>
                        {/* <AnimationOnScroll animateIn="animate__fadeInRight"> */}
                            <img src={one} alt='step' style={imageStyle} />
                            {/* </AnimationOnScroll> */}
                        </style.imageContainer>
                    
                    <style.imageContainerv2><img src={one2} alt='step' style={imageStyle} /></style.imageContainerv2>
                    <style.Column>
                        <style.DescriptionContainer style={{ marginRight: '-10px' }}>
                        {/* <AnimationOnScroll animateIn="animate__fadeInUp"> */}
                            <style.SectionContainer>
                                <style.StepNumber>{info1.step}</style.StepNumber>
                                <style.Section>
                                    <style.DescriptionTitle>{info1.title}</style.DescriptionTitle>
                                    {
                                        info1.titleDescription.map((p, index) => (
                                            <style.DescriptionText key={index}>{p}</style.DescriptionText>
                                        ))
                                    }
                                </style.Section>
                               
                                </style.SectionContainer>
                            {/* </AnimationOnScroll> */}
                        </style.DescriptionContainer>
                    </style.Column>
                </style.Step1Container>
            </style.StepContainer>

            <style.StepContainer>
                <style.imageContainer2>
                    <img src={two} alt='step' style={imageStyle} />
                </style.imageContainer2>
                <style.Column>
                    <style.DescriptionContainer>
                        <style.SectionContainer>
                            <style.StepNumber>{info2.step}</style.StepNumber>
                            <style.Section>


                                <style.DescriptionTitle>{info2.title}</style.DescriptionTitle>
                                {
                                    info2.titleDescription.map((p, index) => (
                                        <style.DescriptionText key={index}>{p}</style.DescriptionText>
                                    ))
                                }

                            </style.Section>
                        </style.SectionContainer>
                    </style.DescriptionContainer>
                </style.Column>
            </style.StepContainer>

            <style.Step3Container>
                <style.Column>
                    <style.DescriptionContainer>
                        <style.SectionContainer>
                            <style.StepNumber>{info3.step}</style.StepNumber>
                            <style.Section>


                                <style.DescriptionTitle>{info3.title}</style.DescriptionTitle>
                                {
                                    info3.titleDescription.map((p, index) => (
                                        <style.DescriptionText key={index}>{p}</style.DescriptionText>
                                    ))
                                }

                            </style.Section>
                        </style.SectionContainer>
                    </style.DescriptionContainer>
                </style.Column>
                <style.imageContainer2><img src={three} alt='step' style={imageStyle} /></style.imageContainer2>

            </style.Step3Container>

            <style.StepContainer>
                <style.imageContainer2>
                    <img src={four} alt='step' style={imageStyle} />
                </style.imageContainer2>
                <style.Column>
                    <style.DescriptionContainer>

                        <style.SectionContainer>
                            <style.StepNumber>{info4.step}</style.StepNumber>
                            <style.Section>


                                <style.DescriptionTitle>{info4.title}</style.DescriptionTitle>
                                {
                                    info4.titleDescription.map((p, index) => (
                                        <style.DescriptionText key={index}>{p}</style.DescriptionText>
                                    ))
                                }

                            </style.Section>
                        </style.SectionContainer>
                    </style.DescriptionContainer>

                    <style.DescriptionContainer>
                        <style.SectionContainer>
                            <style.StepNumber>{info5.step}</style.StepNumber>
                            <style.Section>


                                <style.DescriptionTitle>{info5.title}</style.DescriptionTitle>
                                {
                                    info5.titleDescription.map((p, index) => (
                                        <style.DescriptionText key={index}>{p}</style.DescriptionText>
                                    ))
                                }

                            </style.Section>
                        </style.SectionContainer>


                    </style.DescriptionContainer>
                </style.Column>

            </style.StepContainer>

        </style.StepsViewContainer>
    )
}