import { useState, useEffect, useRef } from 'react'
import * as style from './style'
import phone1 from '../../assets/phone1.svg'
import phone2 from '../../assets/phone2.svg'
import phone3 from '../../assets/phone3.svg'
import hours from '../../assets/24hours.svg'
import contestsDemo from '../../assets/contests-demo.svg'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";

const title1 = "Contests are released every 24 hours for participants to compete"
const title2 = "Participants watch video submissions and vote using emojis"
const title3 = "Participants with the highest votes share the cash prize"
const allTitles = [title1, title2, title3];
const info1Title = "Put your brand under a spotlight"

const info1Text1 = "Other advertising platforms have your brand competing with your competitors to secure digital attention from your audience."
const info1Text2 = "Dope reserves an entire day just for your brand! Only one contest can be hosted per day to prevent your competitors from stealing your audience!"

const info2Title = "Stop wasting time creating content. Let your audience do it for you!"
const info2Text1 = "Contest video submissions created by your audience are like “mini-commercials” for your brand."
const info2Text2 = "Brand-themed contest prompts incorporate trending topics to keep viewers entertained."

const imageStyle = {
    'max-width': '100%',
    'max-height': '100%',
    'transition': 'fade-in-out 0.4s'
}

export default function InfoScreen() {
    const [title, setTitle] = useState(title1);
    var count = useRef(0);

    const phone1focus = count.current === 1? {'opacity':'1'}:{'opacity':'0.5'}
    const phone2focus = count.current === 2? {'opacity':'1'}:{'opacity':'0.5'}
    const phone3focus = count.current === 3? {'opacity':'1'}:{'opacity':'0.5'}
    // const [text, setText] = useState(whatText);
    // const [text2, setText2] = useState(whatText2);
    
    useEffect(() => {
        const interval = setInterval(() => {
            if(count.current === 3) count.current = 0;
            setTitle(allTitles[count.current]);
            count.current = count.current + 1;
        }, 5000);
        return () => clearInterval(interval);
        }, []);
    
    return (
        <style.Container id='el1'>
        <style.InfoScreenContainer>

            {/* <style.InfoScreen> */}
                <style.section1>
                <style.Title>{title}</style.Title>
                
                <style.PhonesContainer>
                    <style.imageContainer>
                        <style.box>
                            <img src={phone1} alt='phone1' style={{...imageStyle,...phone1focus}}/>
                            <style.cover/>
                        </style.box>
                    </style.imageContainer>

                    <style.imageContainer>
                        <style.box>
                            <img src={phone2} alt='phone2' style={{...imageStyle,...phone2focus}}/>
                            <style.cover/>
                        </style.box>
                        
                        </style.imageContainer>
                    <style.imageContainer>
                        <style.box>
                            <img src={phone3} alt='phone3' style={{...imageStyle,...phone3focus}}/>
                            <style.cover/>
                        </style.box>
                    </style.imageContainer>
                </style.PhonesContainer>
                </style.section1>

                <style.section2>
                    <style.InfoBox>
                        <style.infoTitle>
                            {info1Title}
                        </style.infoTitle>
                        <style.infoText>
                            {info1Text1}
                        </style.infoText>
                        <style.infoText>
                            {info1Text2}
                        </style.infoText>
                    </style.InfoBox>

                    {/* <AnimationOnScroll animateIn="animate__bounceIn"> */}
                        <style.InfoMedia>
                            <img src={hours} alt="spotlight"/>
                        </style.InfoMedia>
                    {/* </AnimationOnScroll> */}
                </style.section2>

                <style.section3>
                <style.InfoMedia>
                    <style.imageContainer2>
                    <img src={contestsDemo} alt="spotlight" style={imageStyle}/>
                    </style.imageContainer2>
                    </style.InfoMedia>
                    <style.InfoBox>
                        <style.infoTitle>
                            {info2Title}
                        </style.infoTitle>
                        <style.infoText>
                            {info2Text1}
                        </style.infoText>
                        <style.infoText>
                            {info2Text2}
                        </style.infoText>
                    </style.InfoBox>
                </style.section3>
                
        </style.InfoScreenContainer>
        </style.Container>
    )
}