import * as style from './style'
import CompanyLogo from '../../assets/9978.svg'
import Logo from '../../assets/logo-full-black.svg'
import Copyright from '../../assets/copyright.svg'
import { useState } from 'react';

const emojiBoxColor = ['#61FBA0','#BB5EFC','#EB4191','#F9BE02','#2CC2EC'];
const emotes = ['😍','😳','💩','😂','😭']

export default function Footer() {
    const [selectedEmote, setSelectedEmote] = useState('');

    function selectStyle(index, emote) {
        if (selectedEmote === '' || selectedEmote === emote) return {backgroundColor:emojiBoxColor[index]}
        if(selectedEmote !== emote) return {opacity:'0.4',backgroundColor:emojiBoxColor[index]}
        
    }

    return (
        <style.Container>
            <style.ContentContainer>
                <style.VoteContainer>
                    <style.VoteHeader>Let us know how we did!</style.VoteHeader>
                    <style.EmojiSelection>
                        {
                            emotes.map((emote,index)=>
                            <style.EmojiBox 
                            key={index} 
                            style={selectStyle(index, emote)}
                            onClick={()=>setSelectedEmote(emote)}><style.Emoji>{emote}</style.Emoji></style.EmojiBox>)
                        }
                    </style.EmojiSelection>
                </style.VoteContainer>
                <style.RowContainer>
                    <style.ImageContainer>
                        <img src={Logo} alt="DOPE"
                        style={imageStyle}
                        />
                    </style.ImageContainer>
                    
                    <div><img src={Copyright} alt="copyright"/></div>
                    <style.ImageContainer><img src={CompanyLogo} alt="9978" style={imageStyle}/></style.ImageContainer>
                    
                </style.RowContainer>
            </style.ContentContainer>
        </style.Container>
    )
}

const imageStyle = {
    'max-height': '100%',
    'max-width': '100%'
}