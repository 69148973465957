import styled from 'styled-components/macro'

export const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    background-repeat: no-repeat;
    height: 1050px;
    
    @media only screen and (max-width: 1072px) {
        height: 650px;
        margin-bottom: 50px;
    }

    
`

export const BackgroundContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`
    
export const ContentContainer = styled.div`
    justify-content: space-evenly;
    display: flex;
    max-width: 1200;
    width: 100%;

    @media only screen and (max-width: 1072px) {
        flex-direction: column;
        align-items: center;
    
    }
`
    
export const HeaderContainer = styled.div`
    margin-top: 300px;
    width: 100%;
    max-width: 555px;
    display: flex;
    flex-direction: column;
    color: white;
    font-family: 'Avenir';

    @media only screen and (max-width: 1072px) {
        width: 90%;
        max-width: 550px;
        margin: 120px 0 80px 0;
    }

`

export const Title = styled.div`
        letter-spacing: -4px;
        font-size: 46px;
        font-weight: 900;
        line-height: 1;

        
`


export const InfoText = styled.div`
        margin-top: 10px;
        font-size: 20px;
        width: 430px;

        @media only screen and (max-width: 1072px) {
            width: 450px;
            height: 100%;
        }

        @media only screen and (max-width: 550px) {
            width: 90%;
            height: 100%;
        }
`

export const NavRowContainer = styled.div`
        margin-top: 200px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 2px;

        @media only screen and (max-width: 1072px) {
            margin-top: 80px;
        }
`

export const Clickable = styled.div`
        cursor: pointer;
        margin-right: 30px;
        @media only screen and (max-width: 500px) {
            font-size: 10px;
        }
`

export const RowContainer = styled.div`
    margin-top: 34px;
    display: flex;
    align-items: center;
    width: 360px;
    justify-content: space-between;
`



export const LearnMoreButton = styled.div`
    font-family: 'Avenir';
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 2px;
    cursor: pointer;
`

export const PhoneContainer = styled.div`
    @media only screen and (max-width: 1072px) {
        display: none;
    }
`

export const MobileNavbar = styled.div`
    z-index: 1;
    background-color: rgba(0,0,0,1);
    display: none;
    height: 60px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    backdrop-filter: blur(10px);
    justify-content: center;

    @media only screen and (max-width: 1072px) {
        display: flex;
        
    }
`

export const navbarContent = styled.div`
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`