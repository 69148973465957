import styled from "styled-components/macro";

export const Container = styled.div`
    height: 300px;
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content:center;
`

export const ContentContainer = styled.div`
    height: 204px;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

`

export const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

export const VoteContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const VoteHeader = styled.div`
    font-size: 22px;
    font-weight: 700;
`

export const EmojiSelection = styled.div`
    width: 360px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 53px;
    margin-top: 16px;
`;

export const EmojiBox = styled.div`
    width: 56px;
    height: 56px;
    background-color: #d8d8d8;
    border-radius: 10px;
    display: inline-grid;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    vertical-align: middle;
    transition: all 0.1s;
    outline-offset: -1px;
    border: 1.5px solid black;

    @media only screen and (max-width: 440px) { 
    }
`;

export const Emoji = styled.div`
    font-size: 38px;
    margin-top: 4px;
`

export const ImageContainer = styled.div`
@media only screen and (max-width: 700px) { 
    max-width: 16vw;
}
`