import styled from 'styled-components/macro'

export const StepsViewContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 82px;
    background-color: white;
    color: black;
    gap: 182px;
    cursor:default;

    @media only screen and (max-width: 1300px) {
        gap: 90px;
    }
`

export const SectionContainer = styled.div`
display: flex;
width:100%;
`

export const Headercontainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: black;
    cursor:default;
`

export const Title = styled.div`
    font-size: 70px;
    font-weight: 500;
    cursor:default;
    text-align: center;
    transition: ease-in-out 0.1s;

    @media only screen and (max-width: 850px) {
        font-size: 50px;
    }

    @media only screen and (max-width: 550px) {
        font-size: 42px;
    }
`

export const Step1Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    align-items: center;
    margin-right: 0;

    @media only screen and (max-width: 1300px) {
        flex-direction: column-reverse;
        gap: 65px;
    }
`

export const imageContainer = styled.div`
    width: 60%;
    height: 100%;
    display: none;
    @media only screen and (min-width: 1300px) {
        display: flex;
        align-items: center;
        justify-content:center;
    display:flex;
    }
`
export const imageContainerv2 = styled.div`
    width: 60%;
    height: 100%;
    display: none;
    @media only screen and (max-width: 1300px) {
        display: flex;
        align-items: center;
        justify-content:center;
    display:flex;
    }

    @media only screen and (max-width: 600px) {
        width: 80%;
    }
`

export const imageContainer2 = styled.div`
    
    align-items: center;
    justify-content:center;
    display:flex;
    @media only screen and (max-width: 1300px) {
        width: 60%;
        height: 100%;   
        align-items: center;
        justify-content:center;
        display:flex;
    }

    @media only screen and (max-width: 500px) {
        width: 75%;
    }
`

export const Text = styled.div`
    font-size: 36px;
    font-weight: 400;
    cursor:default;
    text-align: center;
    transition: ease-in-out 0.1s;

    @media only screen and (max-width: 672px) {
        font-size: 18px;
    }
`

export const StepVideoScreen = styled.div`
    width: 610px;
    height:406px;
    background-color: grey;
    border-radius: 20px;
    cursor:default;
    transition: ease-in-out 0.1s;

    @media only screen and (max-width: 682px) {
        width: 100vw;
        border-radius: 0;
    }
`

export const StepNumber = styled.div`
    margin-right: 14px;
    margin-top: 7px;
    font-size: 70px;
    height: 100%;
    font-weight: 900;
    z-index: 1;
    line-height: 0.5;
    @media only screen and (max-width: 1072px) {
        
    }
`
export const Column = styled.div`
    display: flex;
    gap: 100px;
    flex-direction: column;
`

export const StepContainer = styled.div`
    width: 100vw;
    justify-content: space-evenly;
    display: flex;
    align-items: center;
    transition: ease-in-out 0.1s;

    @media only screen and (max-width: 1300px) {
        flex-direction: column-reverse;
        gap: 65px;
    }
`

export const Step3Container = styled.div`
    width: 100vw;
    justify-content: space-evenly;
    display: flex;
    align-items: center;
    transition: ease-in-out 0.1s;

    @media only screen and (max-width: 1300px) {
        flex-direction: column;
        gap: 65px;
    }
`

export const DescriptionContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor:default;
    transition: ease-in-out 0.1s;
    gap:20px;

    @media only screen and (max-width: 1072px) {
        flex-direction: row;
        height: 100%;
        width: 100%;
    }

    
    
`



export const Section = styled.div`
    display: flex;
    width:100%;
    flex-direction: column;
    gap:20px;
    @media only screen and (max-width: 1072px) {
        width: 70%;
    }
`

export const DescriptionTitle = styled.div`
    font-size: 28px;
    font-weight: 900;
    letter-spacing: 5px;
    margin-bottom: 0px;
    transition: ease-in-out 0.1s;
    @media only screen and (max-width: 600px) {
        font-size: 20px;
    }
`

export const title2 = styled.div`
font-family: 'Avenir';
    font-weight: 900;
    font-style: normal;
    font-size: 60px;
`

export const DescriptionText = styled.div`
    font-size: 36px;
    
    width: 496px;
    font-weight: 400;
    transition: ease-in-out 0.1s;

    

    @media only screen and (max-width: 600px) {
        font-size: 34px;
        width: 300px;
    }

    @media only screen and (max-width: 500px) {
        font-size: 30px;
    }
`

export const HelpTitle = styled.div`
    font-size: 20px;
    font-weight: 700;
    line-height: 1.05;
    transition: ease-in-out 0.1s;

    @media only screen and (max-width: 600px) {
        font-size: 18px;
    }
`

export const HelpBlock = styled.div`
    display: flex;
`

export const HelpText = styled.div`
    font-size: 20px;
    width: 300px;
    margin-top: 10px;
    font-weight: 400;
    transition: ease-in-out 0.1s;

    @media only screen and (max-width: 1072px) {
        width: 250px;
    }

    @media only screen and (max-width: 600px) {
        font-size: 16px;
        width: 200px;
    }

    @media only screen and (max-width: 500px) {
        font-size: 15px;
        width: 200px;
    }
`

export const HelpTextNumber = styled.div`
    display:flex;
    font-size: 20px;
    margin-right: 5px;
    margin-top: 10px;
    font-weight: 400;
    transition: ease-in-out 0.1s;

    @media only screen and (max-width: 600px) {
        font-size: 16px;
    }

    @media only screen and (max-width: 500px) {
        font-size: 15px;
    }
`