import styled from 'styled-components/macro'

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 696px;
    width: 100%;
`

export const ContentContainer = styled.div`
        width: 100%;
        max-width: 726px;
        height: 406px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
`

export const Title = styled.div`
    font-weight: 500;
    font-size: 47px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 640px;

    @media only screen and (max-width: 786px) {
        font-size: 6vw;
    }
`
export const TitleText = styled.div`
    font-weight: normal;
    font-size: 22px;
    @media only screen and (max-width: 786px) {
        font-size: 3vw;

    }

    @media only screen and (max-width: 500px) {
        font-size: 4vw;
    }
`
export const TitleText2 = styled.div`
    font-weight: 700;
    font-size: 20px;
    @media only screen and (max-width: 786px) {
        font-size: 3vw;

    }
`

export const RowContainer = styled.div`
    width: 90%;
    max-width: 620px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media only screen and (max-width: 601px) {
        
    }
`
export const RowTitle = styled.div`
    font-size: 14px;
    font-weight: 900;
    letter-spacing: 2px;

    @media only screen and (max-width: 786px) {
        font-size:2vw;
    }
`

export const RowText = styled.div`
    text-align: center;
    font-size: 16px;
    width: 240px;

    @media only screen and (max-width: 786px) {
        font-size: 14px;
        width: 220px;
    }
`

export const BoxContainer = styled.div`
    gap:10px;
    align-items: center;
    display: flex;
    flex-direction: column;
`