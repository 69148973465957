import './App.css';
import Footer from './components/footer/footer';
import InfoScreen from './components/infoScreen/infoScreen';
import InfoScreen3 from './components/infoScreen3/info-screen';
import Jumbotron from './components/jumbotron/jumbotron';
import StepsView from './components/stepsView/steps-view';

function App() {
  return (
    <>
    <Jumbotron />
    <InfoScreen />
    <StepsView />
    <InfoScreen3 />
    <Footer />
    </>
  );
}

export default App;
