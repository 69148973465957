export const steps = [
    {
        step: '1',
        title: 'DESIGN A CONTEST',
        titleDescription: ['We will work intimately with you and your marketing team to design a thrilling, trendy contest and an eye-catching promo!'],
        helpTitle: 'We will help you:',
        helpSteps: ['design a trendy challenge',
                    'pick a catchy hashtag',
                    'pick the best emojis',
                    'pick an eye-catching promo',
                    'suggest a prize amount' ]
    },
    {
        step: '2',
        title: 'SCHEDULE A CONTEST',
        titleDescription: ["Once you approve of the contest design, reserve a date for the contest!","Launching a new product soon? Tell us ahead and we will schedule the contest on the best day for promotion!"],
        helpTitle: 'We will help you:',
        helpSteps: ['pick the best day to host',
                    'reserve the entire day for you' ]
    },
    {
        step: '3',
        title: 'PROMOTIONAL PERIOD',
        titleDescription: ["As your scheduled contest approaches, we will run promos to your target demographics."],
        helpTitle: 'We will help you:',
        helpSteps: ['post promo material on ALL of our social media accounts.',
                    'target your audience by demographics' ]
    },
    {
        step: '4',
        title: 'CONTEST STARTS',
        titleDescription: ["Your contest will be delivered  to your target audience on the iOS app.","They have 24 hours to  participate and vote."],
        helpTitle: 'We will help you:',
        helpSteps: ['keep an eye on the contest',
                    'alert you of any updates' ]
    },
    {
        step: '5',
        title: 'CONTEST ENDS',
        titleDescription: ["The contest will end after 24 hours, but not our relationship.","We will continue finding trending topics for your brand and alert you to schedule."],
        helpTitle: 'We will help you:',
        helpSteps: ['compile any findings of how your audience reacted',
                    'share our findings through a one-one meeting.' ]
    }
    // {
    //     step: '6',
    //     title: 'POST CONTEST',
    //     titleDescription: ["Our relationship does not end here. We would love to maintain a long, fruitful relationship."],
    //     helpTitle: 'We will continue to help you:',
    //     helpSteps: ['find trending topics to design contests for your brand, and you alert when to schedule it.' ]
    // },
]