import styled from 'styled-components/macro'


export const imageContainer = styled.div`
    width: 20vw;
    height: 100%;
    position: relative;
`



export const box = styled.div`
    // width: 100%;
    // height: 100%;
    // position: absolute;
    // top: 0;
    // left: 0;
    // opacity: 0.7;
`

export const cover = styled.div`
//   z-index: 9;
//   background: rgba(1,1,1,0.5);
//   position: relative;
`

export const imageContainer2 = styled.div`
    

    @media only screen and (max-width: 700px) {
        width: 70vw;
        height: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
     }
`
// export const SelectorsContainer = styled.div`
//     margin-right: 55px;
//     margin-bottom: 58px;
//     display: flex;
//     color: rgba(255,255,255,0.2);
//     font-family: 'Avenir';
//     flex-direction: row;
//     font-weight: 900;
//     font-size: 38px;
//     letter-spacing: -4px;
//     gap: 30px;
//     align-self:end;
//     justify-content: right;
// `

// export const Selectors = styled.div`
//         gap: 30px;
//         display: flex;
//         flex-direction: row;

// `
// export const Clickable = styled.div`
//         cursor: pointer;
//         transition: ease-in-out 0.1s;

//         font-size: 40px;
//         font-weight: 900;
//         letter-spacing: -2px;
//         @media only screen and (max-width: 1274px) and (min-width: 786px){
//             font-size: 3vw;
//         }
//         &:hover {
//             color: white;
//         }
// `

export const Container = styled.div`
display:flex;
justify-content: center;
align-items: center;

`

export const InfoScreenContainer = styled.div`
    cursor: default;
    height: 100%;
    max-width: 1300px;
    display: flex;
    color: white;
    font-family: 'Avenir';
    flex-direction: column;
    align-items: center;
`

export const section1 = styled.div`
    display: flex;
    color: white;
    font-family: 'Avenir';
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;

    
`
export const section2 = styled.div`
    display: flex;
    color: white;
    font-family: 'Avenir';
    justify-content: space-between;
    align-items: center;
    margin-bottom: 100px;
    width: 1052px;
    max-width: 100%;

    @media only screen and (max-width: 1300px) {
       flex-direction: column;
       gap: 50px;
       width: 100%;
    }
`

export const section3 = styled.div`
    display: flex;
    color: white;
    font-family: 'Avenir';
    justify-content: space-between;
    align-items: center;
    margin-bottom: 100px;
    width: 1224px;

    @media only screen and (max-width: 1300px) {
        flex-direction: column-reverse;
        gap: 50px;
        align-items: center;
        width: 100%;
     }
`

export const InfoMedia = styled.div`
`


export const InfoBox = styled.div`
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 30px;
`
export const infoTitle = styled.text`
    font-size: 32px;
    max-width: 520px;

    @media only screen and (max-width: 600px){
        width: 80%;
        font-size: 32px;
    }
`
export const infoText = styled.text`
    max-width: 460px;
    font-size: 18px;
`


export const Title = styled.div`
        max-width: 570px;
        text-align: center;
        font-family: 'Avenir';
        font-size: 36px;
        font-weight: 500;
        letter-spacing: -2px;
        animation: fade 0.3s linear;
        @media only screen and (max-width: 600px){
            width: 80%;
            font-size: 32px;
        }
`

// export const Text = styled.div`
//         margin-left: 2px;
//         max-width: 460px;
//         font-size: 22px;
//         font-weight: normal;
//         gap: 42px;
//         display: flex;
//         flex-direction: column;

//         @media only screen and (max-width: 1024px) and (min-width: 786px){
//             font-size: 2vw;
//         }
// `

export const PhonesContainer = styled.div`
    margin-top: 80px;
    display: flex;
    gap: 90px;

    @media only screen and (max-width: 1274px) {
        gap: 4vw;
    }

`;


// export const VideoScreen = styled.div`
//         width: 52%;
//         background-color: grey;

//         @media only screen and (max-width: 786px) {
//             position: absolute;
//             width: 100%;
//         }
// `

// export const InfoScreen = styled.div`
//         width: 48%;
//         justify-content: space-between;
//         display: flex;
//         flex-direction: column;
//         background-color: none;

//         @media only screen and (max-width: 786px) {
//             position: relative;
//             align-items: center;
//             width: 100%;
//         }
// `

// export const Info = styled.div`
//         margin-top: 72px;
//         margin-left: 72px;
//         margin-right: 40px;

//         @media only screen and (max-width: 786px) {
//             margin: 0 50px;
//         }
// `

