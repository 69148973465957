import './style.scss'

const largeButton = {
    width: '280px',
    height: '60px',
    borderRadius: '18px'
}
const largeText = {
    fontSize: '17px'
}

const smallButton = {
    width: '160px',
    height: '40px',
    borderRadius: '25px'
}
const smallText = {
    fontSize: '10px'
}

export default function HostButton({size}) {

    function handleClick(e) {
        e.preventDefault();
    }

    const setSize = (size) => {
        switch(size){
            case 'large':
                return(
                    <a href='https://tally.so/r/mOalAk' target="_blank" rel="noreferrer" style={{'textDecoration': 'none'}}>
                    <div className='button' style={largeButton}>
                    <div className='text' 
                    style={largeText}>
                        HOST A CONTEST
                    </div>
                </div>
                </a>
                )
            case 'small':
                return(
                    <a href='https://tally.so/r/mOalAk' target="_blank" rel="noreferrer" style={{'textDecoration': 'none'}}>
                    <div className='button' style={smallButton}>
                    <div className='text' 
                    style={smallText}>
                        HOST A CONTEST
                    </div>
                </div>
                </a>
                )
            default:
                return (
                    <a href='https://tally.so/r/mOalAk' target="_blank" rel="noreferrer" style={{'textDecoration': 'none'}}>
                <div className='button'>
                    
                <div className='text' 
                >
                    HOST A CONTEST
                </div>
                
            </div>
            </a>
                )
                
        }
    }

    return (
        setSize(size)
    )
        
    
}