import * as style from './style'
import Phone from '../../assets/phone.svg'
import HostButton from '../hostButton/host-button'
import Logo from '../../assets/logo-full.svg'
import { main } from '../../constants/styles'
import bg from '../../assets/background.svg'
import twitter30 from '../../assets/twitter-30.svg'
import twitter60 from '../../assets/twitter-60.svg'
const titleText = "host a contest,"
const titleText2 = "make your brand "
const titleText3 = "memorable"
const infoText = "The best marketing doesn’t feel like marketing. \nGive your audience a thrilling experience\nwhile promoting your brand!"

export default function Jumbotron() {
    function handleLearnMore(e) {
        e.preventDefault()
        let element = document.getElementById("el1");
        element.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
          inline: 'start'
        });
    }

    return (

        <style.Container style={{ backgroundImage: `linear-gradient(to right, rgba(1, 1, 1, 0.6), rgba(1, 1, 1, 1)), url(${bg})` }}>
            {/* <style.MobileNavbar>
                <style.navbarContent>
                <img src={Logo} alt="DOPE"/>
                <HostButton size='small'/>
                </style.navbarContent>
                
            </style.MobileNavbar> */}

            <style.ContentContainer>
                <style.HeaderContainer>
                    <style.Title>{titleText}</style.Title>
                    <div style={{ display: 'inline' }}>
                        <style.Title style={{ display: 'inline' }}>{titleText2}</style.Title>
                        <style.Title style={{ display: 'inline', color: main.dopeRed }}>{titleText3}</style.Title>
                    </div>
                    <style.InfoText>{infoText}</style.InfoText>
                    <style.RowContainer>
                        <HostButton />
                        <style.LearnMoreButton onClick={(e) => handleLearnMore(e)}>LEARN MORE</style.LearnMoreButton>
                    </style.RowContainer>
                    <style.NavRowContainer>
                        <img src={Logo} alt="DOPE" />
                        <style.Clickable><a href="https://twitter.com/dopegameshow" target="_blank" rel="noreferrer"><img src={twitter30} alt="twitter"/></a></style.Clickable>
                        {/* <style.Clickable>ANALYTICS</style.Clickable>
                        <style.Clickable>CONTACT</style.Clickable> */} 
                    </style.NavRowContainer>
                </style.HeaderContainer>

                <style.PhoneContainer>
                    <img src={Phone} alt='phone' />
                </style.PhoneContainer>

            </style.ContentContainer>
        </style.Container>
    )
}